<template>
  <main>
    <section class="section hero-section">
      <div class="container">
        <div class="hero">
          <h1 class="hero__title">CAREERS</h1>
        </div>
      </div>
    </section>
    <section class="section about-section">
      <div class="container">
        <div class="about">
          <div class="about-image">
            <div class="image">
              <img src="@/assets/images/team.webp" alt="" />
            </div>
          </div>
          <div class="about-content">
            <Title>{{ $store.state.siteData.pages.careers.title }}</Title>
            <p class="main-para">
              {{ $store.state.siteData.pages.careers.main_para }}
            </p>
            <p class="sub-para">
              {{ $store.state.siteData.pages.careers.sub_para }}
            </p>
            <h3
              class="cta"
              v-html="$store.state.siteData.pages.careers.cta"
            ></h3>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Accordion from "@/components/ui/accordion/Accordion.vue";
import Title from "@/components/utils/Title.vue";
import { mapActions } from "vuex";

export default {
  name: "CareersPage",
  components: { Title },
  metaInfo: {
    title: "Join the best tech team of Vadodara - AV DEVS",
    meta: [
      {
        name: "description",
        content:
          "Want to make career with AV DEVS?  Join our team by applying at hr@avdevs.com.",
      },
    ],
  },
  mounted() {
    this.fillTargets();
  },
  methods: {
    ...mapActions(["fillTargets"]),
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  background: url("~@/assets/images/careers-bg.webp") center/cover;
}

.about-section {
  .about-content {
    .title {
      text-transform: none;
      margin-bottom: 2rem;
    }

    .main-para {
      font-size: 1.125rem;
      font-weight: 600;
    }

    .sub-para {
      margin-bottom: 2rem;
    }

    .cta {
      font-size: 1.5rem;
      font-weight: 700;
      color: $text-dark;
    }

    ::v-deep .cta > .cta-link {
      color: $text-dark;
      position: relative;
      transition: color 150ms ease-out;

      &:hover {
        color: $primary-color;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        background-color: $primary-color;
        height: 3px;
      }
    }
  }
}
</style>
